import React, { useEffect, useState } from 'react';
// import postImg42 from '../../assets/blog/post42.webp';
// import postImg43 from '../../assets/blog/post43.webp';
// import postImg44 from '../../assets/blog/post44.webp';
// import postImg45 from '../../assets/blog/post45.webp';
import logoImg from '../../assets/logo-white.png';
import { Link } from 'react-router-dom';
import { constant } from '../../utils/constant';

const Footer = () => {
  const [topics, setTopics] = useState([]);

  const fetchTopics = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_CATEGORY);
      const result = await response.json();
      if (result.success) {
        setTopics(result.data);
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };
  useEffect(() => {
    fetchTopics();
  }, []);
  return (
    <div>
      <footer className="footer footer1 mt-5">
        {/* <div className="footer-top">
          <div className="container">
            <div className="box-bottom-border">
              <div className="section-heading">
                <h2 className="title">Follow Me On Instagram</h2>
              </div>
              <div className="instagram-wrap-layout1">
                <a
                  href="https://www.instagram.com/sarcastic_us/"
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // For security best practices
                  className="follow-btn"
                >
                  Follow Me
                  <i className="bi bi-send" />
                </a>

                <div className="row g-3">
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-scandal">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg42}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-mimosa">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg43}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-selago">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg44}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-old-lace">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg45}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-main">
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-4 col-12">
                <div className="footer-widget">
                  <div className="footer-about pe-lg-1">
                    <div className="logo-holder">
                      <Link
                        href="/"
                        className="link-wrap img-height-100"
                        aria-label="Site Logo"
                      >
                        <img width={180} height={46} src={logoImg} alt="logo" />
                      </Link>
                    </div>
                    <p className="description">
                      We’re not just another content page, we’re a movement, a
                      lifestyle, a meme mecca where you laugh, learn, and share.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget ps-lg-3">
                  <h3 className="widget-title h3-small">About</h3>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="./">Home</Link>
                      </li>
                      <li>
                        <Link to="./about">About</Link>
                      </li>
                      <li>
                        <Link to="./contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h3 className="widget-title h3-small">Features</h3>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="archive-layout1.html">Technology</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Politics</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Middle East</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Culture Foram</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">United Kingdom</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Features</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h3 className="widget-title h3-small">Categories</h3>
                  <div className="footer-menu double">
                    <ul style={{ listStyle: "none" }}>
                      {topics &&
                        topics.map((cat, index) => (
                          // Uncomment this line if needed: <ul key={index}>
                          <li key={index}>
                            <Link to={`/categories/${cat._id}`}>
                              {cat?.name}
                            </Link>
                          </li>
                          // Uncomment this line if needed: </ul>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-widget">
                  <p className="widget-title h3-small">Follow Us</p>
                  <div className="axil-social social-layout-1 size-small gap-12">
                    <ul>
                      <li className="facebook">
                        <Link
                          to="https://www.facebook.com/SarcasmLol"
                          aria-label="Learn more from SarcasmLol on Facebook"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-facebook" />
                        </Link>
                      </li>
                      <li className="instagram">
                        <Link
                          to="https://www.instagram.com/sarcastic_us"
                          aria-label="Learn more from SarcasmLol on Instagram"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-instagram" />
                        </Link>
                      </li>
                      <li className="youtube">
                        <Link
                          to="https://www.youtube.com/@Sarcasmyoutube"
                          aria-label="Learn more from SarcasmLol on YouTube"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-youtube" />
                        </Link>
                      </li>
                      <li className="twitter">
                        <Link
                          to="https://x.com/sarcastic_us"
                          aria-label="Learn more from SarcasmLol on Twitter"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-twitter" />
                        </Link>
                      </li>
                      <li className="threads">
                        <Link
                          to="https://www.threads.net/@sarcastic_us"
                          aria-label="Learn more from SarcasmLol on Threads"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-threads" />
                        </Link>
                      </li>
                    </ul>
                    <ul>
                      <li className="snapchat">
                        <Link
                          to="https://www.snapchat.com/add/sarcasm.og"
                          aria-label="Learn more from SarcasmLol on Snapchat"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-snapchat" />
                        </Link>
                      </li>
                      <li className="linkedin">
                        <Link
                          to="https://www.linkedin.com/company/sarcasm-media"
                          aria-label="Learn more from SarcasmLol on LinkedIn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-linkedin" />
                        </Link>
                      </li>
                      <li className="reddit">
                        <Link
                          to="https://www.reddit.com/r/Sarcasm_reddit/"
                          aria-label="Learn more from SarcasmLol on Reddit"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-reddit" />
                        </Link>
                      </li>
                      <li className="whatsapp">
                        <Link
                          to="https://whatsapp.com/channel/0029VapGFlT0LKZDZCaogN19"
                          aria-label="Join the SarcasmLol WhatsApp Channel"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-whatsapp" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h3 className="widget-title h3-small">Support</h3>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Music News</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Style News</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Entertainment</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Executive</a>
                      </li>
                      <li>
                        <a href="404.html">404</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-copyright">
            <span className="copyright-text">© 2024. All rights reserved</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
