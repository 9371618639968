import React from "react";
// import postImg7 from '../../assets/blog/post7.webp';
// import profileImg3 from '../../assets/blog/profile3.webp';
// import profileImg4 from '../../assets/blog/profile4.webp';
// import profileImg5 from '../../assets/blog/profile5.webp';
// import postImg8 from '../../assets/blog/post8.webp';
// import postImg9 from '../../assets/blog/post9.webp';
import { Link } from "react-router-dom";
import { formatTitleForURL } from "../../helper/FormatUrl";

const TopStories = ({ data, allCategory }) => {
  const getCategoryName = (categoryId) => {
    const category = allCategory.find((cat) => cat._id === categoryId);
    return category ? category.name : "Unknown Category";
  };
  const datas = data.reverse();
  console.log(data, "dtas");

  return (
    <div>
      <section className="post-wrap-layout3 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="section-heading heading-style-1">
            <h2 className="title">Top Stories</h2>
            <Link
              // to="/blog/12"
              className="link-wrap"
            >
              {/* Go to Stories{' '} */}
              {/* <span className="icon-holder">
                <i className="bi bi-arrow-right-short"></i>
              </span> */}
            </Link>
          </div>
          <div className="row g-3">
            {data.length > 0 &&
              data?.slice?.(0, 6)?.map((item) =>
                item.bannerImageUrl !== null ? (
                  <div className="col-lg-3" key={Math.random()}>
                    <div className="h-100 post-box-layout4 box-border-dark-1 radius-default padding-20 bg-color-old-lace box-shadow-large shadow-style-2 transition-default">
                      <div className="figure-holder radius-default">
                        <Link
                          to={`/blog/${item._id}/${formatTitleForURL(
                            item?.title
                          )}`}
                          className="link-wrap"
                        >
                          <img
                            className="object-fit-cover w-100"
                            height="250"
                            src={item?.bannerImageUrl}
                            alt="Post"
                          />
                        </Link>
                      </div>
                      <div className="content-holder">
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <Link
                                to={`/blog/${item?._id}/${formatTitleForURL(
                                  item?.title
                                )}`}
                              >
                                {getCategoryName(item?.blogCategoryId)}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title color-dark-1-fixed underline-animation">
                          <Link
                            to={`/blog/${item._id}/${formatTitleForURL(
                              item?.title
                            )}`}
                            className="link-wrap"
                          >
                            {item?.title}
                          </Link>
                        </h3>
                        {/* 
                      <ul className="entry-meta color-dark-1-fixed">
                        <li className="post-author">
                          <Link to={`/blog/${item.recentBlog._id}`} Z>
                            <img src={profileImg3} alt="Author" />
                            Kristin Watson
                          </Link>
                        </li>
                        <li>
                          <i className="bi bi-clock"></i>9 min read
                        </li>
                        <li>
                          <i className="bi bi-eye"></i>1k
                        </li>
                      </ul>
                      <Link
                        to="/blog/12"
                        className="btn-text color-dark-1-fixed"
                      >
                        See Details
                        <span className="icon-holder">
                          <i className="bi bi-arrow-right"></i>
                        </span>{' '}
                      </Link> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopStories;
