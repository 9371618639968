import React, { useState, useEffect, useRef } from 'react';
import '../../app.css';
import logoPng from '../../assets/logo.png';
import MobileMenu from './MobileMenu';
import HeaderActions from './HeaderActions';
import { Link } from 'react-router-dom';
import { constant } from '../../utils/constant';

const Header = ({ handleSideBar }) => {
  const [theme, setTheme] = useState('light');
  const [isSticky, setIsSticky] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [topics, setTopics] = useState([]);
  const menuRef = useRef(null);
  const sidebarRef = useRef(null);
  const stickyPlaceholderRef = useRef(null);

  const fetchTopics = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_CATEGORY);
      const result = await response.json();
      if (result.success) {
        setTopics(result.data.reverse());
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    const handleScroll = () => {
      const menu = menuRef.current;
      const topbarHeight = document.getElementById('topbar-wrap')?.offsetHeight || 0;
      const targetScroll = topbarHeight;

      if (window.scrollY > targetScroll && !isSticky) {
        setIsSticky(true);
        if (menu && stickyPlaceholderRef.current) {
          stickyPlaceholderRef.current.style.height = `${menu.offsetHeight}px`;
        }
      } else if (window.scrollY <= targetScroll && isSticky) {
        setIsSticky(false);
        if (stickyPlaceholderRef.current) {
          stickyPlaceholderRef.current.style.height = '0';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  useEffect(() => {
    fetchTopics();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) && // Check if click is outside sidebar
        sidebarOpen
      ) {
        setSidebarOpen(false); // Close the sidebar
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [sidebarOpen]);

  const toggleTheme = (newTheme) => setTheme(newTheme);

  const toggleSidebar = (isOpen) => setSidebarOpen(isOpen);

  return (
    <header className={`header header1 sticky-on ${isSticky ? "sticky" : ""}`}>
      <div id="topbar-wrap">
        {/* <div className="topbar-global color-dark-1-fixed">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <div className="news-feed-wrap">
                <div id="news-feed-slider" className="news-feed-slider initially-none">
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Underwater Exercise Is Used Weak Of Muscles Thats Good
                    </a>
                  </div>
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Tesla’s Cooking Up A New Way To Wire
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4">
                <div className="current-date d-lg-block d-none">July 5, 2023</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div id="sticky-placeholder" ref={stickyPlaceholderRef}></div>

      {/* Custom Sidebar */}
      <div
        ref={sidebarRef}
        className={`custom-sidebar ${sidebarOpen ? "open" : ""}`}
      >
        <div className="custom-sidebar-content">
          <button
            className="close-sidebar-btn"
            onClick={() => toggleSidebar(false)}
          >
            ✖
          </button>
          <ul className="sidebar-menu">
            <li>
              <Link to="/" onClick={() => toggleSidebar(false)}>
                <span className="menu-icon">🏠</span> Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={() => toggleSidebar(false)}>
                <span className="menu-icon">💡</span> About
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={() => toggleSidebar(false)}>
                <span className="menu-icon">📧</span> Contact
              </Link>
            </li>
            <li>
              <Link to="https://agency.sarcasm.co/" onClick={() => toggleSidebar(false)}>
                <span className="menu-icon">🖥️</span> Agency
              </Link>
            </li>
            <li>
              <button
                className="categories-dropdown-btn"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <span className="menu-icon">📂</span> Categories
              </button>
              {dropdownOpen && (
                <ul className="categories-dropdown">
                  {topics.map((cat) => (
                    <li key={cat._id}>
                      <Link
                        to={`/categories/${cat._id}`}
                        onClick={() => {
                          setDropdownOpen(false);
                          toggleSidebar(false);
                        }}
                      >
                        {cat.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div id="navbar-wrap" ref={menuRef} className="navbar-wrap">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            {/* Sidebar Toggle Button */}
            <button
              className="sidebar-toggle-btn"
              onClick={() => toggleSidebar(!sidebarOpen)}
            >
              <img src={constant.CONST_HAMBURGER_ICON} alt="Menu" />
            </button>
            <Link to="/" className="desktop-logo">
              <img width="180" height="46" src={logoPng} alt="logo" />
            </Link>
            <MobileMenu />
            <HeaderActions handleSideBar={handleSideBar} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
