import React, { useEffect, useMemo, useState } from "react";
import "../../app.css";
import BlogBody from "./BlogBody";
// import RecomendedTopics from './blogsidebar/RecomendedTopics';
import { Link, useParams } from "react-router-dom";
import { constant } from "../../utils/constant";
import { Skeleton } from "@mui/material";
import { formatDate } from "../../helper/DateFormater";
import { Helmet } from "react-helmet";
import { formatTitleForURL } from "../../helper/FormatUrl";

const Blog = ({ allCategory }) => {
  const [data, setData] = useState(null);
  const [recentBlog, setRecentBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const categories = useMemo(
    () => ["Entertainment", "News", "Marketing", "Sports"],
    []
  );
  const [currentCategory, setCurrentCategory] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${constant.CONST_SARCASM_BLOG}/${id}`);
      const json = await response.json();
      if (json.success) setData(json.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecentBlog = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_RECENT);
      const json = await response.json();
      if (json.success) setRecentBlog(json.data);
    } catch (error) {
      console.error("Error fetching recent blogs:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchRecentBlog();
    window.scrollTo(0, 0);
  }, [id]);

  const getCategoryName = (categoryId) => {
    const category = allCategory.find((cat) => cat._id === categoryId);
    return category ? category.name : "Unknown Category";
  };

  useEffect(() => {
    if (recentBlog.length > 0 && data) {
      const filteredBlogs = recentBlog.reduce((acc, item) => {
        if (categories.includes(item.categoryName)) {
          acc[item.categoryName] = acc[item.categoryName] || [];
          acc[item.categoryName].push(item);
        }
        return acc;
      }, {});

      const categoryName = getCategoryName(data.blogCategoryId);
      setCurrentCategory(filteredBlogs[categoryName] || []);
    }
  }, [recentBlog, data, categories, allCategory]);

  const currentURL = window.location.href;
  const title = `Check out this blog post: ${data?.title || "Loading..."}`;
  if (loading) {
    return (
      <div style={{ marginTop: "1rem" }}>
        {[...Array(7)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width="65%"
            style={{ margin: "0 auto 20px" }}
            height={100}
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.metaTitle}</title>
        <meta name="description" content={data.metaDescription} />
      </Helmet>
      <section className="space-top-60 space-bottom-60 single-blog-wrap1 bg-color-light-1 transition-default">
        <div className="container">
          <div className="row sticky-coloum-wrap">
            <div className="col-lg-8 sticky-coloum-item">
              <div className="single-blog-content content-layout1 pe-lg-4">
                {/* <BlogPostLayout /> */}
                {/* <BlogArticleContent /> */}
                {/* <TagShareSection /> */}
                {/* <AuthorBio />
                <PostNavigation />
                <CommentSection /> */}
                {/* Social Media Share Buttons */}
                <div className="entry-category style-2 color-dark-1">
                  <ul>
                    <li>
                      <a href="archive-layout1.html">
                        {data?.blogCategoryName}
                      </a>
                    </li>
                  </ul>
                </div>
                <h1 className="entry-title color-dark-1">{data?.title}</h1>
                <ul className="entry-meta color-dark-1">
                  <li className="post-author">
                    Posted By :<a>{data?.posted_by} |</a>
                    Date: {formatDate(data?.updatedAt)}
                  </li>
                </ul>
                <div className="axil-social social-layout-1 size-large gap-12">
                  <ul>
                    <li className="twitter">
                      <a
                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                          title
                        )}&url=${encodeURIComponent(currentURL)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on Twitter"
                      >
                        <i className="bi bi-twitter-x" />
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href={`https://www.linkedin.com/shareArticle?title=${encodeURIComponent(
                          title
                        )}&url=${encodeURIComponent(currentURL)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on LinkedIn"
                      >
                        <i className="bi bi-linkedin" />
                      </a>
                    </li>
                    <li className="whatsapp">
                      <a
                        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                          title + " " + currentURL
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on WhatsApp"
                      >
                        <i className="bi bi-whatsapp"></i>
                      </a>
                    </li>
                    <li className="facebook">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          currentURL
                        )}&quote=${encodeURIComponent(title)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on Facebook"
                      >
                        <i className="bi bi-facebook" />
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href={`https://www.instagram.com/?url=${encodeURIComponent(
                          currentURL
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on Instagram"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="singleBlogContent">
                  <BlogBody data={data} />
                </div>
                {/* -------------------------------------------- */}
              </div>
            </div>
            <div className="col-lg-4 sticky-coloum-item">
              {currentCategory[0]?.recentBlogs &&
                currentCategory[0]?.recentBlogs?.slice(1, 6)?.map((item) =>
                  item?.bannerImageUrl !== null && item._id !== id ? (
                    <div
                      className="col-12"
                      key={item?._id}
                      style={{ padding: "1rem" }}
                    >
                      <div className="box-border-dark-1 radius-default padding-20 figure-scale-animation bg-color-scandal box-shadow-large shadow-style-2 transition-default">
                        <div className="figure-holder radius-default">
                          <Link
                            to={`/blog/${item?._id}/${formatTitleForURL(
                              item?.title
                            )}`}
                            className="link-wrap"
                          >
                            <img
                              className="object-fit-cover w-100"
                              height="280"
                              src={item?.bannerImageUrl}
                              alt="Post"
                            />
                          </Link>
                        </div>
                        <div className="content-holder d-flex justify-content-center mt-2">
                          <div>
                            <div className="entry-category style-2 color-dark-1-fixed ">
                              <ul>
                                <li>
                                  <Link
                                    to={`/blog/${item?._id}/${formatTitleForURL(
                                      item?.title
                                    )}`}
                                  >
                                    {getCategoryName(item?.blogCategoryId)}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <h3 className="entry-title color-dark-1-fixed underline-animation">
                              <Link
                                to={`/blog/${item?._id}/${formatTitleForURL(
                                  item?.title
                                )}`}
                                className="link-wrap"
                              >
                                {item?.title}
                              </Link>
                            </h3>
                            {/* <p className="entry-description color-dark-1-fixed">
                            Nam eget lorem mattis, consequat felis quis, luctus
                            augue. Aenean ac iaculis enim.
                          </p> */}
                            {/* <ul className="entry-meta color-dark-1-fixed">
                            <li className="post-author">
                              <a href="author.html">
                                <img src={profileImg1} alt="Author" />
                                Esther Howard
                              </a>
                            </li>
                            <li>
                              <i className="bi bi-clock" />3 min read
                            </li>
                            <li>
                              <i className="bi bi-eye" />
                              4k
                            </li>
                          </ul> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
