import React from 'react';
// import profileImg from '../../assets/blog/profile4.webp';
import { Link } from 'react-router-dom';

const HeaderActions = ({ handleSideBar }) => {
  return (
    <div className="headerSocial">
      <ul>
        <li className="facebook">
          <Link
            to="https://www.facebook.com/SarcasmLol"
            aria-label="Learn more from SarcasmLol on Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-facebook" />
          </Link>
        </li>
        <li className="instagram">
          <Link
            to="https://www.instagram.com/sarcastic_us"
            aria-label="Learn more from SarcasmLol on Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-instagram" />
          </Link>
        </li>
        <li className="youtube">
          <Link
            to="https://www.youtube.com/@Sarcasmyoutube"
            aria-label="Learn more from SarcasmLol on YouTube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-youtube" />
          </Link>
        </li>
        <li className="twitter">
          <Link
            to="https://x.com/sarcastic_us"
            aria-label="Learn more from SarcasmLol on Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-twitter" />
          </Link>
        </li>
        {/* <li className="threads">
          <Link
            to="https://www.threads.net/@sarcastic_us"
            aria-label="Learn more from SarcasmLol on Threads"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-threads" />
          </Link>
        </li> */}
        {/* <li className="snapchat">
          <Link
            to="https://www.snapchat.com/add/sarcasm.og"
            aria-label="Learn more from SarcasmLol on Snapchat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-snapchat" />
          </Link>
        </li> */}
        {/* <li className="linkedin">
          <Link
            to="https://www.linkedin.com/company/sarcasm-media"
            aria-label="Learn more from SarcasmLol on LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-linkedin" />
          </Link>
        </li> */}
        {/* <li className="reddit">
          <Link
            to="https://www.reddit.com/r/Sarcasm_reddit/"
            aria-label="Learn more from SarcasmLol on Reddit"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-reddit" />
          </Link>
        </li> */}
        {/* <li className="whatsapp">
          <Link
            to="https://whatsapp.com/channel/0029VapGFlT0LKZDZCaogN19"
            aria-label="Join the SarcasmLol WhatsApp Channel"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-whatsapp" />
          </Link>
        </li> */}
      </ul>
    </div>
    // <div className="d-flex align-items-center gap-3">
    //   <div className="d-lg-block d-none">
    //     <div className="search-trigger-wrap">
    //       <i className="bi bi-search"></i>
    //     </div>
    //   </div>

    //   <div className="d-lg-block d-none">
    //     <div className="profile-wrap dropdown-item-wrap">
    //       <div className="navbar navbar-expand-md">
    //         <div className="dropdown">
    //           <Link
    //             className="dropdown-toggle"
    //             to="/"
    //             id="navbarDropdown2"
    //             role="button"
    //             data-bs-toggle="dropdown"
    //             aria-haspopup="true"
    //             aria-expanded="false"
    //             aria-label="Profile"
    //           >
    //             <span className="thumble-holder img-height-100">
    //               <img width="40" height="40" src={profileImg} alt="Profile" />
    //             </span>
    //           </Link>
    //           <div
    //             className="dropdown-menu dropdown-menu-end animate slideIn"
    //             aria-labelledby="navbarDropdown2"
    //           >
    //             <div className="dropdown-menu-inner">
    //               <div className="profile-content with-icon">
    //                 <ul>
    //                   <li>
    //                     <a href="author.html">
    //                       <div className="icon-holder">
    //                         <i className="bi bi-person"></i>
    //                       </div>
    //                       Profile
    //                     </a>
    //                   </li>
    //                   <li>
    //                     <a href="author.html">
    //                       <div className="icon-holder">
    //                         <i className="bi bi-activity"></i>
    //                       </div>
    //                       Activity Log
    //                     </a>
    //                   </li>
    //                   <li>
    //                     <a href="author.html">
    //                       <div className="icon-holder">
    //                         <i className="bi bi-plus-square"></i>
    //                       </div>
    //                       Library
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <div className="profile-content">
    //                 <ul>
    //                   <li>
    //                     <a href="author.html">Become a Member</a>
    //                   </li>
    //                   <li>
    //                     <a href="author.html">Apply for author verification</a>
    //                   </li>
    //                   <li>
    //                     <a href="author.html">Settings</a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <div className="mt-3">
    //                 <a
    //                   href="#"
    //                   className="w-100 axil-btn axil-btn-ghost btn-color-alter axil-btn-small"
    //                 >
    //                   Sign Out
    //                 </a>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="mobile-menu-btn d-lg-none d-block">
    //     <button
    //       aria-label="Offcanvas"
    //       className="btn-wrap"
    //       data-bs-toggle="offcanvas"
    //       data-bs-target="#mobilemenu-popup"
    //       onClick={handleSideBar}
    //     >
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //     </button>
    //   </div>
    // </div>
  );
};

export default HeaderActions;
