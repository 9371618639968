import React from "react";
import "../../app.css";
import { Link } from "react-router-dom";
import { formatTitleForURL } from "../../helper/FormatUrl";

const CategoryHighlightSection = ({ data, allCategory, title }) => {
  const getCategoryName = (categoryId) => {
    const category = allCategory.find((cat) => cat._id === categoryId);
    return category ? category.name : "Unknown Category";
  };
  const mostRecent = data?.reduce((prev, current) => {
    const prevDate = prev?.createdAt ? new Date(prev?.createdAt) : new Date(0);
    const currentDate = current?.createdAt
      ? new Date(current.createdAt)
      : new Date(0);
    return currentDate > prevDate ? current : prev;
  }, {});
  console.log(mostRecent, "recent");

  return (
    <div>
      <section className="post-wrap-layout4 space-top-50 bg-color-light-1 transition-default latestStory">
        <div className="container">
          <div className="section-heading heading-style-1">
            <h2 className="title">{title}</h2>
          </div>
          <div className="row g-3">
            <div className="col-lg-8 latestStoryLeft">
              <div className="row g-3">
                <div className="col-lg-12">
                  <div className="post-box-layout5 box-border-dark-1 radius-default">
                    <div className="figure-holder radius-medium">
                      <Link
                        to={`/blog/${mostRecent?._id}/${mostRecent?.title}`}
                        className="link-wrap figure-overlay img-height-100"
                      >
                        <img
                          className="object-fit-cover"
                          width="810"
                          height="558"
                          src={mostRecent?.bannerImageUrl}
                          alt="Post"
                        />
                      </Link>
                    </div>
                    <div className="content-holder">
                      <div className="entry-category style-2 color-dark-1-fixed">
                        <ul>
                          <li>
                            <Link
                              to={`/blog/${mostRecent?._id}/${mostRecent?.title}`}
                            >
                              {getCategoryName(mostRecent?.blogCategoryId)}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="entry-title h3-large color-light-1-fixed underline-animation">
                        <Link
                          to={`/blog/${mostRecent?._id}/${formatTitleForURL(
                            mostRecent?.title
                          )}`}
                          className="link-wrap"
                        >
                          {mostRecent?.title}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 latestStoryRight">
              <div className="widget-post post-layout1">
                {data?.slice(1, 5)?.map((item) =>
                  item?._id ? (
                    <div className="post-box" key={item?._id}>
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay img-height-100"
                        >
                          <img src={item?.bannerImageUrl} alt="Post" />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-dark-1 underline-animation h3-extra-small">
                          <Link
                            to={`/blog/${item?._id}/${formatTitleForURL(
                              item?.title
                            )}`}
                            className="link-wrap"
                          >
                            {item?.title}
                          </Link>
                        </h3>
                        <ul className="entry-meta color-dark-1">
                          <li>
                            <i className="bi bi-clock"></i>8 min read
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CategoryHighlightSection;
