import React from 'react';
import aboutImg1 from '../../assets/about/about1.webp';
import aboutImg2 from '../../assets/about/about2.webp';
import aboutImg3 from '../../assets/about/about3.webp';

const About = () => {
  return (
    <div>
      <section className="about-wrap-layout-1 space-top-60 space-bottom-60 space-bottom-md-30 bg-color-selago transition-default">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6 order-lg-2">
              {/* <div className="about-box-layout-1 box-border-dark-1-fixed radius-default">
                <div className="figure-holder radius-medium img-height-100">
                  <img width="700" height="566" src={aboutImg2} alt="ABOUT" />
                </div>
              </div> */}
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="about-box-layout-1 pe-lg-5">
                <div className="content-holder">
                  <h2 className="entry-title color-dark-1-fixed"> Who We Are </h2>
                  {/* <h2 className="entry-title color-dark-1-fixed">Our Story</h2> */}
                  {/* <p className="entry-description color-dark-1-fixed">
                    On August 15th, an alarming email popped up in the inbox of
                    Diana Pearl...
                  </p> */}

                  <p className="entry-description color-dark-1-fixed">
                    Welcome to the House of LOLs, where sarcasm is the main dish and viral content is the side hustle. Founded a long, long time ago, Sarcasm has gone from a cheeky little Instagram page to a global powerhouse with over 11.2 million followers and counting. What started as a random post to make friends laugh has now spiraled into a community that spans the globe—because let’s face it, everyone loves a good roast paired with their daily dose of sports, news, and entertainment.
                    <br />
                  </p>
                  <p className="entry-description color-dark-1-fixed mt-3">At Sarcasm, we don’t just publish memes; we create cultural moments. From turning trending news into gut-busting jokes to giving sports updates with a side of snark, we’re the ultimate blend of fun and facts. Think of us as the friend who keeps you in the loop.</p>

                  {/* <p className="entry-description color-dark-1-fixed">
                    Notes generate random lorem text when needed...
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrap-layout-1 space-top-60 space-bottom-60 space-bottom-md-30 bg-color-light-1 transition-default">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6">
              {/* <div className="about-box-layout-1 box-border-dark-1 radius-default">
                <div className="figure-holder radius-medium img-height-100">
                  <img width="700" height="566" src={aboutImg1} alt="ABOUT" />
                </div>
              </div> */}
            </div>

            <div className="col-lg-6">
              <div className="about-box-layout-1">
                <div className="content-holder">
                  <h2 className="title"> What We Do</h2>
                  <p className="description">What DON’T we do? (Except take ourselves seriously.) We’re your one-stop shop for:</p>
                  <ul className="list-style-1">
                    <li>
                      <b>Sports:</b> Missed last night’s match? Don’t worry, we’ll recap it with a savage twist. Who scored, who choked, and who’s now a meme—it’s all here.
                    </li>
                    <li>
                      {' '}
                      <b>News:</b> We keep you updated on what’s happening around the world, but forget the boring headlines. We bring you the tea, the memes, and the arcasm.
                    </li>
                    <li>
                      {' '}
                      <b>Entertainment:</b> From celeb drama to movie reviews so brutally honest even Rotten Tomatoes would blush, we’ve got you covered.
                    </li>
                    <li>
                      {' '}
                      <b>Marketing: </b>Yep, we’re also marketing wizards. Brands come to us when they want to go viral, and we deliver. Our campaigns aren’t just promotions; they’re internet-breaking events.
                    </li>
                    <blockquote>We’re not just another content page; we’re a movement, a lifestyle, a meme mecca where you laugh, learn, and share.</blockquote>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrap-layout-2 space-top-60 space-bottom-60 space-bottom-md-30 bg-color-light-2">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6 order-lg-2">
              {/* <div className="about-box-layout-1 box-border-dark-1 radius-default">
                <div className="figure-holder radius-medium img-height-100">
                  <a href="https://www.youtube.com/watch?v=1iIZeIy7TqM" aria-label="Youtube Video" className="play-btn style-2 size-regular popup-youtube">
                    <i className="bi bi-play-fill"></i>
                  </a>
                  <img width="700" height="566" src={aboutImg3} alt="ABOUT" />
                </div>
              </div> */}
            </div>

            <div className="col-lg-6 order-lg-1">
              <div className="about-box-layout-1">
                <div className="content-holder">
                  <h2>Our Sarcastic Story</h2>
                  <p>Ah, the glorious origins of Sarcasm. Legend has it that one fateful day, our great team was created and we decided the world needed more sarcasm and fewer boring Instagram posts. Armed with nothing but a sharp wit, a knack for timing, and questionable Wi-Fi, the first meme was born. It didn’t just make people laugh—it made them follow.</p>
                  <p>As the page grew, so did the ambitions. From roasting relatable situations to turning global headlines into punchlines, Sarcasm quickly became the place where humor met relevance. But why stop at memes? The audience wanted more, so we expanded. Sports updates? Check. Celeb gossip? Of course. Breaking news? Only if it’s funny.</p>
                  {/* <h3 className="h3-medium mb-2">Network Terms:</h3>
                  <p>Most text editors like MS Word or Lotus Notes generate random lorem text...</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      {/* <section className="team-wrap-layout2 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="section-heading heading-style-10">
            <h2 className="title">Meet Our Team</h2>
            <p className="description">
              Most text editors like MS Word or Lotus Notes generate random
              lorem text when needed...
            </p>
          </div>
          <div className="row g-3">
            {teamMembers.map((member, index) => (
              <div className="col-md-4" key={index}>
                <div className="team-layout-2">
                  <div className="figure-holder">
                    <a href="author.html" className="link-wrap img-height-100">
                      <img
                        width="520"
                        height="520"
                        src={member.image}
                        alt="Team"
                      />
                    </a>
                  </div>
                  <div className="content-holder">
                    <div>
                      <h3 className="entry-title h3-medium">
                        <a href="author.html" className="link-wrap">
                          {member.name}
                        </a>
                      </h3>
                      <div className="entry-designation text-b4 f-w-500">
                        {member.designation}
                      </div>
                    </div>
                    <div className="axil-social social-layout-1 size-medium gap-8">
                      <ul>
                        {member.social.map((social, i) => (
                          <li key={i} className={social.platform}>
                            <a
                              aria-label={`Learn more from ${social.platform}`}
                              href={social.link}
                            >
                              <i className={`bi bi-${social.platform}`}></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </div>
  );
};

const teamMembers = [
  {
    name: 'Georges Embolo',
    designation: 'Publisher',
    image: '../../assets/team/team4.webp',
    social: [
      { platform: 'pinterest', link: '#' },
      { platform: 'twitter', link: '#' },
      { platform: 'mail-fast', link: '#' },
    ],
  },
  {
    name: 'Nayah Tantoh',
    designation: 'Publisher',
    image: '../../assets/team/team5.webp',
    social: [
      { platform: 'pinterest', link: '#' },
      { platform: 'twitter', link: '#' },
      { platform: 'mail-fast', link: '#' },
    ],
  },
  {
    name: 'John Philipe',
    designation: 'Publisher',
    image: 'a../../assets/team/team6.webp',
    social: [
      { platform: 'pinterest', link: '#' },
      { platform: 'twitter', link: '#' },
      { platform: 'mail-fast', link: '#' },
    ],
  },
];

export default About;
